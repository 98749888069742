import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HubSpotForm } from 'components/ui/HubSpotForm'
import AdImage from 'assets/images/frontend/login/usps-can-date-time-min.png'
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn'
import LoginHeader from './LoginHeader'

const DepricatedOauthPage = () => {
    const [shownWarningMessage, setShownWarningMessage] = useState(true)
    const history = useHistory()

    useRedirectIfLoggedIn()

    const hideWarningMessage = () => setShownWarningMessage(false)

    return (
        <main className="login-page">
            <section>
                <LoginHeader title="Update your account" />

                <div className="login-form">
                    {shownWarningMessage && (
                        <div className="warn-msg contact">
                            ShipperHQ no longer supports account log in using
                            Facebook or Twitter. Please fill out the form below
                            so we can locate your account and send instructions
                            on how to update your account.
                        </div>
                    )}

                    <HubSpotForm
                        region="na1"
                        portalId="20068459"
                        formId="f672fcb9-f777-419f-b03a-7309f5fb442d"
                        onSubmit={hideWarningMessage}
                    />

                    <footer>
                        <button
                            onClick={history.goBack}
                            className="bottom-link"
                        >
                            <i className="fa fa-long-arrow-left" /> Back to
                            Login
                        </button>
                    </footer>
                </div>
            </section>
            <aside>
                <div className="adspace-container">
                    <h2 className="sm">Delivery Date & Time</h2>
                    <h4 className="semi">
                        Now Available for USPS & Canada Post
                    </h4>
                    <img src={AdImage} />
                </div>
            </aside>
        </main>
    )
}

export default DepricatedOauthPage
