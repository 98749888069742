//
import React from 'react'

/**
 * Alert Properties
 */

export default function Alert(props) {
    const { message, type = 'danger' } = props
    return (
        <div
            className={`alert alert-${type} text-left`}
            id="signin-modal-alert-box"
        >
            <ul>
                <li>{message}</li>
            </ul>
        </div>
    )
}
