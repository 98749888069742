//

export const PROFILE_INFO_SUBMIT_SUCCESS = 'profileInfoSubmitSuccess'
export const PROFILE_INFO_SUBMIT_FAILURE = 'profileInfoSubmitFailure'
export const SIGNUP_SUBMIT_SUCCESS = 'signupSubmitSuccess'
export const SIGNUP_SUBMIT_FAILURE = 'signupSubmitFailure'
export const LOGIN_SUBMIT_SUCCESS = 'loginSubmitSuccess'
export const LOGIN_SUBMIT_FAILURE = 'loginSubmitFailure'

export function submitFormEvent(event, formId) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event,
        formId,
    })
}
