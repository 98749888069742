//
import React from 'react'
import { Route, Switch } from 'react-router'
import { Router as ReactRouter } from 'react-router-dom'
import AppDataLoader from '../dashboard/AppDataLoader'
import FrontendDetailsPage from 'components/dashboard/integrations/containers/Details/FrontendDetailsPage'
import IntegrationsIndex from './integrations'
import {
    SignupPage,
    LoginPage,
    ForgotPasswordPage,
    DepricatedOauthPage,
    UnlockPage,
} from './signup'
import { ProfileInfoPage } from './profile_info'
import history from './history'
import { useSelector } from 'react-redux'

function integrationsSelector({ app }) {
    /* $FlowFixMe */
    return app?.integrations?.available_integrations?.map(
        (integration) =>
            '/' + (integration.slug ? integration.slug : integration.name)
    )
}

export default function Router() {
    const marketplaceRoutes = useSelector(integrationsSelector)

    return (
        <ReactRouter history={history}>
            <AppDataLoader>
                <Switch>
                    <Route path="/marketplace" component={IntegrationsIndex} />
                    {marketplaceRoutes && (
                        <Route
                            path={marketplaceRoutes}
                            component={FrontendDetailsPage}
                        />
                    )}

                    <Route path="/login" component={LoginPage} />
                    <Route path="/signup" component={SignupPage} />
                    <Route path="/unlock_access" component={UnlockPage} />
                    <Route
                        path="/forgot_password"
                        component={ForgotPasswordPage}
                    />
                    <Route
                        path="/profile_info/:userId/edit"
                        component={ProfileInfoPage}
                    />
                    <Route
                        path="/depricated_oauth"
                        component={DepricatedOauthPage}
                    />
                </Switch>
            </AppDataLoader>
        </ReactRouter>
    )
}
