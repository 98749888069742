//
import React from 'react'
import LoginForm from './LoginForm'
import BackButton from './BackButton'
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn'
import { RandomUpsell } from './RandomUpsell'
import AdReturns from 'assets/images/frontend/signup-login/signup-returns.png'
import AdAnalytics from 'assets/images/frontend/signup-login/signup-analytics.png'

const upsellContent = [
    {
        pretitle: 'what’s new',
        title: 'Build a Profitable Shipping </br>Strategy',
        description:
            'Uncover eye-opening insights into your order and shipping data to optimize your checkout experience.',
        imagePath: AdAnalytics,
        link: 'https://shipperhq.com/shipping-analytics'
    },
    {
        pretitle: 'what’s new',
        title: 'Simplified Returns',
        description:
            'Cover the cost of returns by enabling Return Shipping—display a duplicate return shipping rate with an added surcharge for each selected shipping method.',
        imagePath: AdReturns,
        link: 'https://shipperhq.com/features#returns'
    },
]

export default function LoginPage() {
    useRedirectIfLoggedIn()

    return (
        <main className="login-page">
            <section>
                <BackButton />
                <LoginForm />
            </section>
            <aside>
                <RandomUpsell upsellContent={upsellContent} />
            </aside>
        </main>
    )
}
