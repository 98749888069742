/**
 * Checks if the password is strong enough
 */
export function isStrongPassword(password) {
    if (!password) {
        return true
    }

    if (password.length < 8) {
        return false
    }

    // unicode-compatible uppercase & lowercase matchers
    const letters = /\p{Lu}/gu
    const lcLetters = /\p{Ll}/gu
    const numbers = /\d/gu

    const lettersCnt = [...password.matchAll(letters)].length
    const lcLettersCnt = [...password.matchAll(lcLetters)].length
    const numbersCnt = [...password.matchAll(numbers)].length

    if (!lettersCnt || !numbersCnt || !lcLettersCnt) {
        return false
    }

    return true
}
