import React from 'react'
import { usePlanId } from './hooks/usePlanId'

export default function PlanIdField() {
    const planId = usePlanId()

    return planId ? (
        <input type="hidden" className="hidden" name="plan_id" value={planId} />
    ) : null
}
