import React from 'react'

export default function AgreedTerms() {
    return (
        <span>
            I have read and agree to the ShipperHQ{' '}
            <a href="https://www.shipperhq.com/useragreement" target="_blank">
                User Agreement
            </a>{' '}
            and{' '}
            <a href="https://www.shipperhq.com/privacy" target="_blank">
                Privacy Policy
            </a>
        </span>
    )
}
