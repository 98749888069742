import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { clearFlashMessages } from '../reducers/modules/app'

export function useFlashMessages() {
    const messagesInitial = useSelector<
        ReduxRootState,
        ReduxRootState['app']['flash']
    >(({ app: { flash = [] } = {} }) => flash)
    const [messages] = useState(messagesInitial)
    const dispatch = useDispatch()
    const noEmptyMessages = messages.filter((message) => !isEmpty(message?.[1]))

    useEffect(() => {
        // once messages are displayed - clear the list of messages
        dispatch(clearFlashMessages())
    }, [])

    return noEmptyMessages
}
