//
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { fieldInput } from 'components/ui/form/renderers'
import ReCAPTCHA from 'react-google-recaptcha'
import LoginHeader from './LoginHeader'
import { required } from 'utils/validators'
import useSubmitForm from 'hooks/useSubmitForm'
import { useHistory } from 'react-router-dom'
import { RECAPTCHA_SITE_KEY } from 'components/constants'

export default function ForgotPasswordFormView() {
    const history = useHistory()
    return (
        <div>
            <LoginHeader title="Forgot your password?" />
            <div className="login-form">
                <FormView />
                <footer>
                    <button onClick={history.goBack} className="bottom-link">
                        <i className="fa fa-long-arrow-left" /> Back to Login
                    </button>
                </footer>
            </div>
        </div>
    )
}

function FormView() {
    const [isSent, setIsSent] = useState(false)
    const { error, loading, submit } = useSubmitForm('/user/password.json')
    const [recaptchaToken, setRecaptchaToken] = useState('')

    const onSignIn = async (values) => {
        values['g-recaptcha-response'] = recaptchaToken
        setIsSent(false)
        if (await submit(values)) {
            setIsSent(true)
        }
    }

    if (isSent) {
        return (
            <div className="info-msg">
                <small>
                    If your email exists, you will receive an email with
                    instructions on how to reset your password.
                </small>
            </div>
        )
    }

    if (error) {
        return (
            <div className="fail-msg">
                <small>{error}</small>
            </div>
        )
    }

    return (
        <>
            <Form onSubmit={onSignIn}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            title="Email"
                            name="user[email]"
                            required={true}
                            /* $FlowFixMe */
                            render={fieldInput}
                            validate={required}
                            fullWidth={true}
                        />
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={setRecaptchaToken}
                            className="recaptcha"
                        />
                        <button
                            type="submit"
                            className="btn btn-lg btn-block btn-orange sharp sign_in_button"
                            disabled={loading}
                        >
                            Send An Email
                        </button>
                    </form>
                )}
            </Form>
        </>
    )
}
