import React from 'react'
import Details from 'components/dashboard/integrations/containers/Details'
import FrontendPage from 'components/frontend/FrontendPage'

export default function FrontendDetailsPage(props) {
    return (
        <div className="integrations">
            <FrontendPage>
                <Details {...props} />
            </FrontendPage>
        </div>
    )
}
