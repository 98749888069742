import React, { useState } from 'react'

export default function CsrfField() {
    const [csrfToken] = useState(() => {
        const csrfTokenEl = document.querySelector('meta[name="csrf-token"]')
        return csrfTokenEl ? csrfTokenEl.getAttribute('content') : ''
    })

    return <input type="hidden" name="authenticity_token" value={csrfToken} />
}
