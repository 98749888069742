//
import React from 'react'
import * as Constants from './constants'
import shopifyIcon from 'assets/images/frontend/platform-shopify-logo.svg'
import magentoIcon from 'assets/images/frontend/platform-magento-logo.svg'
import bigCommerceyIcon from 'assets/images/frontend/platform-bigcom-logo.svg'
import salesforce from 'assets/images/frontend/platform-salesforce-logo.svg'
import wooCommerceIcon from 'assets/images/frontend/platform-woocommerce-logo.svg'
import adobeCommerceIcon from 'assets/images/frontend/platform-adobecommerce-logo.svg'
import zoeyIcon from 'assets/images/frontend/platform-zoey-logo.svg'
import commercetoolsIcon from 'assets/images/frontend/platform-commercetools-logo.svg'
import cartcomIcon from 'assets/images/frontend/platform-cartcom-logo.svg'
/**
 * PlatformButton Properties
 */

// TODO: Update image imports

const logos = {
    /* $FlowFixMe */
    [Constants.SHOPIFY]: {
        url: shopifyIcon,
        alt: 'Shopify',
    },
    /* $FlowFixMe */
    [Constants.MAGENTO2]: {
        url: magentoIcon,
        alt: 'Magento',
    },
    /* $FlowFixMe */
    [Constants.BIGCOMMERCE]: {
        url: bigCommerceyIcon,
        alt: 'BigCommerce',
    },
    /* $FlowFixMe */
    [Constants.SALESFORCE]: {
        url: salesforce,
        alt: 'Salesforce',
    },
    /* $FlowFixMe */
    [Constants.WOOCOMMERCE]: {
        url: wooCommerceIcon,
        alt: 'WooCommerce',
    },
    /* $FlowFixMe */
    [Constants.ADOBECOMMERCE]: {
        url: adobeCommerceIcon,
        alt: 'AdobeCommerce',
    },
    /* $FlowFixMe */
    [Constants.ZOEY]: {
        url: zoeyIcon,
        alt: 'Zoey',
    },
    /* $FlowFixMe */
    [Constants.COMMERCETOOLS]: {
        url: commercetoolsIcon,
        alt: 'Commercetools',
    },
    /* $FlowFixMe */
    [Constants.CARTCOM]: {
        url: cartcomIcon,
        alt: 'Cart.com',
    },
}

export default function PlatformIcon(props) {
    const { platform } = props
    const logo = logos[platform]

    return (
        <img src={logo?.url} className={platform + '-logo'} alt={logo?.alt} />
    )
}
