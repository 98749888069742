import React from 'react'
import { useHistory } from 'react-router'

export default function BackButton() {
    const history = useHistory()
    return (
        <button onClick={history.goBack} className="page-back">
            <i className="fa fa-angle-left" /> Back
        </button>
    )
}
