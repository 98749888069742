import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Field, Form } from 'react-final-form'
import {
    fieldInput,
    fieldCheckboxStd,
    fieldSelect,
} from 'components/ui/form/renderers'
import useSubmitForm from 'hooks/useSubmitForm'
import { required } from 'utils/validators'
import AgreedTerms from './AgreedTerms'
import Alert from 'components/ui/Alert'
import {
    PROFILE_INFO_SUBMIT_SUCCESS,
    PROFILE_INFO_SUBMIT_FAILURE,
    submitFormEvent,
} from '../../../utils/googleTagManager'
import { CircleIconWithBG } from 'components/dashboard/trialWizard/CircleIconWithBG'
import {
    TrialTitle,
    TrialText,
    TrialButton,
} from 'components/dashboard/trialWizard/styles'

export default function SalesforceProfileInfoForm() {
    const { loading, error, submitProfileInfo, currencyCodes } =
        useSalesforceForm()

    const submitAndRedirect = async (values) => {
        const result = await submitProfileInfo({ ...values })
        if (result) {
            submitFormEvent(PROFILE_INFO_SUBMIT_SUCCESS, 'profileInfoForm')
            window.location.href = '/ratesmgr/onboarding'
            return
        } else {
            submitFormEvent(PROFILE_INFO_SUBMIT_FAILURE, 'profileInfoForm')
        }
    }

    return (
        <div className="main-cont trial-wizard">
            <div>
                {error && <Alert message={error} />}
                <Form
                    initialValues={{ currency_code: 'USD' }}
                    onSubmit={submitAndRedirect}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <header>
                                <CircleIconWithBG iconClassName="fas fa-user-alt" />
                                <TrialTitle>Update Account Profile</TrialTitle>
                                <TrialText>
                                    Please reset your password and fill in the
                                    information below to finish setting up your
                                    account.
                                </TrialText>
                            </header>

                            <Field
                                title="Password"
                                name="password"
                                type="password"
                                render={fieldInput}
                                validate={required}
                                fullWidth={true}
                                placeholder="Password"
                                required={true}
                            />
                            <p className="help-block">
                                Password must have at least 8 characters
                            </p>
                            <Field
                                title="Password Confirmation"
                                name="password_confirmation"
                                type="password"
                                render={fieldInput}
                                validate={required}
                                fullWidth={true}
                                placeholder="Confirm Password"
                                required={true}
                            />
                            <Field
                                title="Billing Currency"
                                validate={required}
                                required={true}
                                name="currency_code"
                                component={fieldSelect}
                                options={currencyCodes}
                                fullWidth={true}
                            />
                            <Field
                                renderTitle={AgreedTerms}
                                name="agreed_terms"
                                className="terms"
                                required={true}
                                validate={required}
                                type="checkbox"
                                component={fieldCheckboxStd}
                                fullWidth={true}
                            />

                            <div className="checkbox mb20">
                                <Field
                                    title="Sign up for emails about new features and other ShipperHQ announcements!"
                                    name="wants_mailing_list"
                                    type="checkbox"
                                    component={fieldCheckboxStd}
                                    fullWidth={true}
                                />
                            </div>
                            <TrialButton disabled={loading} type="submit">
                                Submit
                            </TrialButton>
                        </form>
                    )}
                </Form>
            </div>
        </div>
    )
}

const enumToObj = ([key, val]) => ({
    title: key,
    value: val,
})

function useSalesforceForm() {
    const { userId } = useParams()
    const { submit, error, loading } = useSubmitForm(
        `/profile_info/${userId}`,
        'patch'
    )

    const { currencyCodes } = useSelector(
        ({ app: { currency_codes = [] } }) => ({
            currencyCodes: currency_codes.map(enumToObj),
        })
    )

    const submitProfileInfo = async (values) => {
        const data = {
            salesforce_first_login_message: {
                password: values.password,
                password_confirmation: values.password_confirmation,
                currency_code: values.currency_code,
                agreed_terms: values.agreed_terms,
            },
            wants_mailing_list: values.wants_mailing_list,
        }

        const response = await submit(data)
        return response
    }

    return {
        loading,
        error,
        submitProfileInfo,
        currencyCodes,
    }
}
