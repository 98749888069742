//
import React from 'react'

window.Routes.ratesmgr_marketplace_path = (slug) => '/' + slug

/**
 * FrontendPage Properties
 */

const FrontendPage = ({ children }) => (
    <div className="page-content">{children}</div>
)

export default FrontendPage
