import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export function useRedirectIfLoggedIn() {
    const loggedIn = useSelector(({ app }) => app.logged_in)
    useEffect(() => {
        if (loggedIn) {
            window.location.href = '/ratesmgr/dashboards'
        }
    }, [loggedIn])
}
