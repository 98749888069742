import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { fieldInput, fieldCheckboxStd } from 'components/ui/form/renderers'
import { required } from 'utils/validators'
import chunk from 'lodash/chunk'
import {
    submitFormEvent,
    SIGNUP_SUBMIT_SUCCESS,
    SIGNUP_SUBMIT_FAILURE,
} from 'utils/googleTagManager'
import SocialButtons from './SocialButtons'
import LoginHeader from './LoginHeader'
import CsrfField from './CsrfField'
import Alert from 'components/ui/Alert'
import SalesforceSignupForm from './SalesforceSignupForm'
import PlanIdField from './PlanIdField'
import PlatformButton from './PlatformButton'
import PlatformIcon from './PlatformIcon'
import ShopifyForm from './ShopifyForm'
import BackButton from './BackButton'
import usePlatformsList from './hooks/usePlatformsList'
import { usePlanId } from './hooks/usePlanId'
import { usePlatform } from './hooks/usePlatform'
import { useSignupForm } from './hooks/useSignupForm'
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn'
import { makeAuthTitle } from './utils/index' // Not adding "index" here is breaking this import
import * as Constants from './constants'
import { isStrongPassword } from 'utils/passwords'
import { RandomUpsell } from './RandomUpsell'
import Ad200k from 'assets/images/frontend/signup-login/login-stat-200.png'
import AdStat90 from 'assets/images/frontend/signup-login/login-stat-90.png'

const MAGENTO2_ID = 8
const WOOCOMMERCE_ID = 7
const ZOEY_ID = 5
const SALESFORCE_ID = 9
const SHOPIFY_ID = 2
const BIGCOMMERCE_ID = 4
const ADOBECOMMERCE_ID = 10
const COMMERCETOOLS_ID = 11
const CARTCOM_ID = 12

const FORMS = {
    [Constants.SHOPIFY]: <ShopifyForm platformId={SHOPIFY_ID} />,
    [Constants.MAGENTO2]: <GenericSignupForm platformId={MAGENTO2_ID} />,
    [Constants.ADOBECOMMERCE]: (
        <GenericSignupForm platformId={ADOBECOMMERCE_ID} />
    ),
    [Constants.BIGCOMMERCE]: (
        <BigCommerceSignupForm platformId={BIGCOMMERCE_ID} />
    ),
    [Constants.WOOCOMMERCE]: <GenericSignupForm platformId={WOOCOMMERCE_ID} />,
    [Constants.SALESFORCE]: <SalesforceSignupForm platformId={SALESFORCE_ID} />,
    [Constants.ZOEY]: <GenericSignupForm platformId={ZOEY_ID} />,
    [Constants.COMMERCETOOLS]: (
        <GenericSignupForm platformId={COMMERCETOOLS_ID} />
    ),
    [Constants.CARTCOM]: <GenericSignupForm platformId={CARTCOM_ID} />,
}

const upsellContent = [
    {
        pretitle: 'real success stories',
        title: 'Enhance your shipping strategy',
        description:
            'Clean Eatz Kitchen increased on-time delivery to a high 90% range after implementing ShipperHQ.',
        imagePath: AdStat90,
        link: 'https://shipperhq.com/customer-stories/clean-eatz',
    },
    {
        pretitle: 'real success stories',
        title: 'Turn shipping into a </br>profit center',
        description:
            'Clean Eatz Kitchen saved $200K in COGS by avoiding resends, with a $40K reduction in labor costs',
        imagePath: Ad200k,
        link: 'https://shipperhq.com/customer-stories/clean-eatz',
    },
]

export default function SignupPage() {
    return (
        <main className="signup-page">
            <section>
                <SignupForm />
            </section>
            <aside>
                <RandomUpsell upsellContent={upsellContent} />
            </aside>
        </main>
    )
}

function SignupForm() {
    const [selectedPlatform, setSelectedPlatform] = usePlatform()
    const goBackToPlatformSelection = () => setSelectedPlatform(null)

    useRedirectIfLoggedIn()

    return (
        <section>
            <BackButton />

            {!selectedPlatform ? (
                <SignupMethods setPlatform={setSelectedPlatform} />
            ) : (
                <div>
                    {FORMS[selectedPlatform]}
                    <footer>
                        <button
                            className="bottom-link"
                            onClick={goBackToPlatformSelection}
                        >
                            <i className="fa fa-long-arrow-left" /> Back to
                            Platforms
                        </button>
                    </footer>
                </div>
            )}
        </section>
    )
}

function SignupMethods({ setPlatform }) {
    const platforms = usePlatformsList()
    const planId = usePlanId()

    const title = makeAuthTitle(planId)

    return (
        <div>
            <LoginHeader
                title={title}
                columns={3}
                subtitle="Select Your Platform"
            />
            <div className="platform-selector">
                {platforms &&
                    chunk(platforms, 4).map((chunkPlatforms) =>
                        chunkPlatforms.map((platform, index) => (
                            <PlatformButton
                                key={`${platform}-${index}`}
                                platform={platform}
                                onClick={setPlatform}
                            />
                        ))
                    )}
            </div>
            <footer>
                <small>
                    Don't see your platform? No problem!{' '}
                    <a href="/contact">Contact us</a>
                </small>
                <br />
                <small>
                    Already have an account? <a href="/login">Log In</a>
                </small>
            </footer>
        </div>
    )
}

const validatePasswords = (values) => {
    const errors = {}

    if (values.password !== values.password_confirmation) {
        errors.password_confirmation = "Passwords don't match!"
    }

    if (!isStrongPassword(values.password)) {
        errors.password =
            'The password is too weak! Please make sure it includes number, upper case and lower case letter and is at least 8 characters long'
    }

    return errors
}

function GenericSignupForm({ platformId }) {
    const { signUp, error, loading } = useSignupForm(platformId)
    const history = useHistory()
    const planId = usePlanId()

    const title = makeAuthTitle(planId)

    const signUpAndRedirect = async (values) => {
        const data = await signUp({ ...values })
        if (data?.user_id) {
            submitFormEvent(SIGNUP_SUBMIT_SUCCESS, 'signupForm')
            window.location.href = '/ratesmgr/onboarding'
        } else if (data) {
            window.location.href = '/ratesmgr/dashboards'
        } else {
            submitFormEvent(SIGNUP_SUBMIT_FAILURE, 'signupForm')
        }
    }

    return (
        <>
            <div>
                <LoginHeader title={title} />
                <div className="login-form">
                    {error && <Alert message={error} />}
                    <Form
                        onSubmit={signUpAndRedirect}
                        validate={validatePasswords}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    title="Email"
                                    name="email"
                                    required={true}
                                    render={fieldInput}
                                    validate={required}
                                    fullWidth={true}
                                    placeholder="Email"
                                />
                                <Field
                                    title="Password"
                                    name="password"
                                    type="password"
                                    render={fieldInput}
                                    validate={required}
                                    fullWidth={true}
                                    placeholder="Password"
                                />
                                <span>
                                    Must be 8 characters long and include a number, uppercase and lower case letter
                                </span>
                                <Field
                                    title="Confirm Password"
                                    name="password_confirmation"
                                    type="password"
                                    render={fieldInput}
                                    validate={required}
                                    fullWidth={true}
                                    placeholder="Confirm Password"
                                />
                                <Field
                                    title="Sign up for emails about new features and ShipperHQ announcements"
                                    name="wants_mailing_list"
                                    type="checkbox"
                                    render={fieldCheckboxStd}
                                    fullWidth={true}
                                    bold={false}
                                />

                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-lg btn-block btn-orange sharp sign_in_button mb30"
                                >
                                    Sign Up Free
                                </button>

                                <SocialButtons type="signup" />
                            </form>
                        )}
                    </Form>
                </div>
            </div>
        </>
    )
}

function BigCommerceSignupForm() {
    const planId = usePlanId()

    const title = makeAuthTitle(planId)
    return (
        <div>
            <LoginHeader title={title} />
            <div className="login-form">
                <form method="post" action="/bigcommerce/install">
                    <PlatformIcon platform={Constants.BIGCOMMERCE} />
                    <p>To Continue Sign Up...</p>
                    <CsrfField />
                    <PlanIdField />
                    <button className="btn btn-orange btn-lg">
                        Install the App
                    </button>
                </form>
            </div>
        </div>
    )
}
