import React, { useState, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from 'components/ui/Alert'
import PlanIdField from './PlanIdField'
import { required } from 'utils/validators'
import { fieldInput, fieldTextarea } from 'components/ui/form/renderers'
import useSubmitForm from 'hooks/useSubmitForm'
import LoginHeader from './LoginHeader'
import { usePlanId } from './hooks/usePlanId'
import { makeAuthTitle } from './utils/index'
import { RECAPTCHA_SITE_KEY } from 'components/constants'

export default function SalesforceSignupForm() {
    const {
        success,
        error,
        submit,
        setRecaptchaToken,
        loading,
        recaptchaToken,
        title,
    } = useSalesforceSignupForm()

    return !success ? (
        <>
            <div>
                <LoginHeader title={title} />
                <div className="login-form">
                    {error && <Alert message={error} />}
                    <Form onSubmit={submit}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <p>
                                    Complete this form so we can get started on
                                    your account and match you with a shipping
                                    expert for a 1:1 conversation
                                </p>
                                <Field
                                    title="Name"
                                    name="name"
                                    render={fieldInput}
                                    validate={required}
                                    required={true}
                                    fullWidth={true}
                                    placeholder="Name"
                                />
                                <Field
                                    title="Email"
                                    name="email"
                                    render={fieldInput}
                                    validate={required}
                                    required={true}
                                    fullWidth={true}
                                    placeholder="Email"
                                />
                                <Field
                                    title="Phone"
                                    name="phone"
                                    render={fieldInput}
                                    validate={required}
                                    required={true}
                                    fullWidth={true}
                                    placeholder="Phone"
                                />
                                <Field
                                    title="Company URL"
                                    name="website"
                                    render={fieldInput}
                                    validate={required}
                                    required={true}
                                    fullWidth={true}
                                    placeholder="Company URL"
                                />
                                <Field
                                    title="What Challenges Are You Looking to Solve"
                                    name="body"
                                    render={fieldTextarea}
                                    fullWidth={true}
                                    placeholder="What Challenges Are You Looking to Solve"
                                />
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={setRecaptchaToken}
                                    className="recaptcha"
                                />
                                <PlanIdField />
                                <button
                                    disabled={loading || !recaptchaToken}
                                    type="submit"
                                    id="create_trial_account"
                                    className="btn btn-lg btn-orange"
                                >
                                    Send My Details
                                </button>
                            </form>
                        )}
                    </Form>
                </div>
            </div>
        </>
    ) : (
        <SalesforceSignupSuccess />
    )
}

function SalesforceSignupSuccess() {
    const navigateHome = () => {
        window.location.href = '/'
    }
    return (
        <>
            <div>
                <LoginHeader title="Thanks for signing up!" columns={3} />
                <div className="thankyou-msg">
                    <p>
                        Your account is being generated and a shipping expert
                        will be in touch with you as soon as possible.
                    </p>
                    <p>
                        We want to make sure we can answer all of your questions
                        and help you get the most out of your trial experience!
                    </p>
                    <button
                        type="button"
                        className="btn btn-lg btn-orange"
                        onClick={navigateHome}
                    >
                        Ok, Great!
                    </button>
                </div>
            </div>
        </>
    )
}

function useSalesforceSignupForm() {
    const [success, setSuccess] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const planId = usePlanId()
    const [title, setTitle] = useState('')
    const { submit, error, loading } = useSubmitForm('/user')

    const submitContactInfo = async (formValues) => {
        const contactInfo = {
            generic_contact_message: {
                ...formValues,
                subject_title: 'Salesforce Commerce Cloud Free Trial Signup',
            },
            'g-recaptcha-response': recaptchaToken,
        }
        await submit(contactInfo)
        if (!error && !loading) {
            setSuccess(true)
        }
    }

    useEffect(() => {
        setTitle(makeAuthTitle(planId))
    }, [planId])

    return {
        success,
        error,
        submit: submitContactInfo,
        setRecaptchaToken,
        recaptchaToken,
        loading,
        title,
    }
}
