import * as Constants from '../constants'

const DEFAULT_TITLE = 'Sign Up for a Free 15 Day Trial'
const PROMOTION_TITLE = 'Sign Up For Your Free ShipperHQ Plan'

/**
 * Creates a title for the login and sign up headers
 * based on the current plan
 *
 * @param {string} planId
 */
export default function makeAuthTitle(planId) {
    const title = Constants.isFreePlan(planId) ? PROMOTION_TITLE : DEFAULT_TITLE
    return title
}
