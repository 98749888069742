import React from 'react'
import PlatformIcon from './PlatformIcon'
import * as Constants from './constants'

const LABELS = {
    [Constants.ADOBECOMMERCE]: 'Adobe Commerce',
    [Constants.BIGCOMMERCE]: 'BigCommerce',
    [Constants.MAGENTO2]: 'Magento',
    [Constants.SHOPIFY]: 'Shopify',
    [Constants.SALESFORCE]: 'Commerce Cloud',
    [Constants.WOOCOMMERCE]: 'WooCommerce',
    [Constants.ZOEY]: 'Zoey',
    [Constants.COMMERCETOOLS]: 'Commercetools',
    [Constants.CARTCOM]: 'Cart.com',
}

const PlatformButton = ({ platform, onClick }) => {
    return (
        <button
            id={`btnPlatform-${platform}`}
            onClick={(e) => onClick(platform)}
            className="list-item-platform"
            data-testid="platform-button"
        >
            <div className="list-item-platform--image">
                <PlatformIcon platform={platform} />
            </div>

            <p className="list-item-platform--title">{LABELS[platform]}</p>
        </button>
    )
}

export default PlatformButton
