import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import * as Constants from '../constants'

export function usePlatform(initialPlatform = null) {
    const location = useLocation()
    const history = useHistory()
    const [platform, setPlatform] = useState(initialPlatform)

    const makeUrl = (platform) => {
        // remove any existing platform from the url since it will
        // be modified directly
        const urlParams = new URLSearchParams(location.search)
        urlParams.delete('platform')

        // convert all other params to string so they can be re-added
        const paramStrings = Array.from(urlParams.entries()).map(
            ([key, value]) => (value ? `${key}=${value}` : key)
        )
        const url = `${location.pathname}?${
            platform ? `platform=${platform}` : ''
        }${platform && paramStrings.length ? '&' : ''}${
            paramStrings.length ? paramStrings.join('&') : ''
        }`
        return url
    }

    const updatePlatform = (platform) => {
        setPlatform(platform)
        const url = makeUrl(platform)
        history.replace(url)
    }

    useEffect(() => {
        if (location.search.match(/salesforce/i)) {
            updatePlatform(Constants.SALESFORCE)
        } else if (location.search.match(/woocommerce/i)) {
            updatePlatform(Constants.WOOCOMMERCE)
        } else if (location.search.match(/zoey/i)) {
            updatePlatform(Constants.ZOEY)
        } else if (location.search.match(/bigcommerce/i)) {
            updatePlatform(Constants.BIGCOMMERCE)
        } else if (location.search.match(/magento/i)) {
            updatePlatform(Constants.MAGENTO2)
        } else if (location.search.match(/shopify/i)) {
            updatePlatform(Constants.SHOPIFY)
        } else if (location.search.match(/adobecommerce/i)) {
            updatePlatform(Constants.ADOBECOMMERCE)
        } else if (location.search.match(/commercetools/i)) {
            updatePlatform(Constants.COMMERCETOOLS)
        } else if (location.search.match(/cartcom/i)) {
            updatePlatform(Constants.CARTCOM)
        } else {
            updatePlatform(null)
        }
    }, [])

    return [platform, updatePlatform]
}
