//
import React from 'react'
import { Route, Switch } from 'react-router'
import FrontendPage from '../FrontendPage'
import IndexPage from 'components/dashboard/integrations/containers/Index'
import 'components/dashboard/carriers/loadLogos'
import { useSelector } from 'react-redux'

const Index = () => {
    const categoryUrl = useSelector(
        ({ app: { integrations: { category_url = '/' } = {} } = {} }) =>
            category_url
    )

    return (
        <div className="integrations">
            <FrontendPage>
                <Switch>
                    <Route
                        exact
                        path={['/marketplace', '/marketplace/' + categoryUrl]}
                        component={IndexPage}
                    />
                </Switch>
                <div className="bottom-main">
                    <div className="max-cont btm-msg">
                        <h3 className="strong">
                            Looking to Join the ShipperHQ Marketplace?
                        </h3>
                        <p>
                            Our partners are upstanding members of the eCommerce
                            community. They supply the highest quality products
                            and services to their customers and are known as the
                            best in their industry.
                        </p>
                        <a className="btn btn-primary" href={'/partnerprogram'}>
                            Apply for Partnership
                        </a>
                    </div>
                </div>
            </FrontendPage>
        </div>
    )
}

export default Index
