//
import React from 'react'
import ForgotPasswordFormView from './ForgotPasswordFormView'
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn'
import { RandomUpsell } from './RandomUpsell'
import AdFish from 'assets/images/frontend/signup-login/password-fish.png'
import AdFlowers from 'assets/images/frontend/signup-login/password-flowers.png'

export default function ForgotPasswordPage() {
    useRedirectIfLoggedIn()

    const upsellContent = [
        {
            pretitle: 'delivery date & time',
            title: 'Make sure every delivery is </br>efficient, fast & fresh',
            description:
                'Ship perishables with confidence and assure your customers will receive their order in a timely manner.',
            imagePath: AdFlowers,
            link: 'https://shipperhq.com/shipping-perishables',
        },
        {
            pretitle: 'delivery date & time',
            title: 'Reduce waste & increase margins',
            description:
                'Ship perishables with confidence knowing goods will get to their destination fast, efficient and fresh.',
            imagePath: AdFish,
            link: 'https://shipperhq.com/shipping-perishables',
        },
    ]

    return (
        <main className="login-page">
            <section>
                <ForgotPasswordFormView />
            </section>
            <aside>
                <RandomUpsell upsellContent={upsellContent} />
            </aside>
        </main>
    )
}
