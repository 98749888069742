export const required = (value) =>
    value ? undefined : 'This is required field'

export const requireNoSpaces = (value) =>
    value.includes(' ') ? "This field shouldn't contain any spaces" : undefined

export const composeValidators = (...validators) => (value) =>
    validators.reduce(
        (error, validator) => error || validator(value),
        undefined
    )
