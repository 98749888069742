import React from 'react'
import styles from './Login.module.css'
import { Carousel } from 'components/ui/Carousel'

interface UpsellContent {
    pretitle: string
    title: string
    description: string
    imagePath: string
    link?: string
}

interface RandomUpsellProps {
    upsellContent: UpsellContent[]
}

export const RandomUpsell: React.FC<RandomUpsellProps> = ({
    upsellContent,
}) => {
    return (
        <div
            className={styles.loginUpsellBox}
            data-testid="randomized-upsell-ad"
        >
            <Carousel>
                {upsellContent.map((content) => {
                    return (
                        <div>
                            <div className={styles.textBox}>
                                <p className={styles.pretitle}>
                                    {content.pretitle}
                                </p>
                                <h2
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: content.title,
                                    }}
                                ></h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: content.description,
                                    }}
                                    className={styles.description}
                                ></p>
                                {content.link && (
                                    <a href={content.link} target='_blank' className={styles.link}>Learn more <i className="fas fa-arrow-right"></i></a>
                                )}
                            </div>
                            <div className={styles.upsellImageBox}>
                                <img
                                    src={content.imagePath}
                                    alt=""
                                    className={styles.upsellImage}
                                />
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}
