//
import React from 'react'
import Logo from 'assets/images/frontend/shq-logo-hz-3c.svg'

/**
 * LoginHeader Properties
 */

export default function LoginHeader(props) {
    const { title, columns, subtitle } = props
    const style = {}

    if (columns) {
        style.gridColumnEnd = columns + 1
    }

    return (
        <header className="signup-intro" style={style}>
            <a href="/">
                <img src={Logo} alt="ShipperHQ" />
            </a>
            {title && <h1>{title}</h1>}
            {subtitle && <h4>{subtitle}</h4>}
        </header>
    )
}
