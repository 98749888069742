//
import React from 'react'
import { Link } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import { fieldInput } from 'components/ui/form/renderers'
import { required } from 'utils/validators'
import {
    submitFormEvent,
    LOGIN_SUBMIT_FAILURE,
    LOGIN_SUBMIT_SUCCESS,
} from 'utils/googleTagManager'
import useSubmitForm from 'hooks/useSubmitForm'
import LoginHeader from './LoginHeader'
import SocialButtons from './SocialButtons'
import Alert from 'components/ui/Alert'
import ShopifyForm from './ShopifyForm'
import BCForm from './BCForm'
import * as Constants from './constants'

/**
 * LoginFormView Properties
 */

const platformForms = {
    [Constants.BIGCOMMERCE]: BCForm,
    [Constants.SHOPIFY]: ShopifyForm,
    [Constants.WOOCOMMERCE]: GenericLogin,
    [Constants.SALESFORCE]: GenericLogin,
    [Constants.ZOEY]: GenericLogin,
    [Constants.MAGENTO2]: GenericLogin,
    [Constants.ADOBECOMMERCE]: GenericLogin,
    [Constants.COMMERCETOOLS]: GenericLogin,
    [Constants.CARTCOM]: GenericLogin,
}

export default function LoginFormView(props) {
    const { onNavigateBack, platform } = props

    const onBack = (e) => {
        e.preventDefault()
        onNavigateBack && onNavigateBack()
        return false
    }

    const PlatformForm = platformForms[platform]

    return (
        <div>
            <div className="login-form">
                <PlatformForm {...props} />
                <footer>
                    <button href="#" className="bottom-link" onClick={onBack}>
                        <i className="fa fa-long-arrow-left" /> Back to
                        Platforms
                    </button>
                </footer>
            </div>
        </div>
    )
}

function GenericLogin(props) {
    const { error, loading, submit } = useSubmitForm('/user/sign_in')

    const onSignIn = async (values) => {
        const payload = await submit(values)

        if (payload?.success) {
            submitFormEvent(LOGIN_SUBMIT_SUCCESS, 'loginForm')
            window.location.href =
                payload.redirect_path || '/ratesmgr/dashboards'
        } else {
            submitFormEvent(LOGIN_SUBMIT_FAILURE, 'loginForm')
        }
    }

    return (
        <>
            <LoginHeader title="Welcome" />
            {error && <Alert message={error} />}
            <Form onSubmit={onSignIn}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            title="Email"
                            name="user[email]"
                            required={true}
                            /* $FlowFixMe */
                            render={fieldInput}
                            validate={required}
                            fullWidth={true}
                        />
                        <Field
                            title="Password"
                            name="user[password]"
                            type="password"
                            required={true}
                            /* $FlowFixMe */
                            render={fieldInput}
                            validate={required}
                            fullWidth={true}
                        />
                        <button
                            type="submit"
                            id="account_login"
                            className="btn btn-lg btn-block btn-orange sharp sign_in_button"
                            disabled={loading}
                        >
                            Login
                        </button>

                        <Link
                            to="/forgot_password"
                            className="mt10 bottom-link mb20"
                        >
                            Forgot Password?
                        </Link>

                        <SocialButtons type="login" />
                    </form>
                )}
            </Form>
        </>
    )
}
