import useSubmitForm from 'hooks/useSubmitForm'
import { usePlanId } from './usePlanId'

export function useSignupForm(platformId) {
    const { submit, error, loading } = useSubmitForm('/user')
    const planId = usePlanId()
    const signUp = async (values) => {
        const user = {
            user: { ...values },
            ...(planId && { plan_id: planId }),
            platform_id: platformId.toString(),
        }
        return await submit(user)
    }

    return { signUp, error, loading }
}
