//
import React from 'react'
import PlatformButton from './PlatformButton'
import LoginFormView from './LoginFormView'
import LoginHeader from './LoginHeader'
import usePlatformsList from './hooks/usePlatformsList'
import { usePlatform } from './hooks/usePlatform'
import useSubmitForm from 'hooks/useSubmitForm'
import { useFlashMessages } from '../../../hooks/useFlashMessages'
import Alert from 'components/ui/Alert'
import chunk from 'lodash/chunk'

/**
 * LoginForm Properties
 */

export default function LoginForm(props) {
    const [selectedPlatform, setSelectedPlatform] = usePlatform()
    const platforms = usePlatformsList()
    const submitForm = useSubmitForm('/user/sign_in')
    const flashMessages = useFlashMessages()

    const onPlatformSelected = (platform) => setSelectedPlatform(platform)

    if (selectedPlatform) {
        return (
            <LoginFormView
                platform={selectedPlatform}
                onNavigateBack={() => setSelectedPlatform(null)}
                loading={submitForm.loading}
                serverError={submitForm.error}
            />
        )
    }

    const messages = (
        <>
            {flashMessages.map(([type, message]) => (
                <Alert
                    type={type === 'notice' ? 'success' : 'danger'}
                    key={message}
                    message={message}
                />
            ))}
        </>
    )

    return (
        <div>
            <LoginHeader title="Select Your Platform to Log In" columns={3} />
            {messages}
            <div className="platform-selector">
                {chunk(platforms, 4).map((chunkPlatforms) =>
                    chunkPlatforms.map((platform, index) => (
                        <PlatformButton
                            key={`${platform}-${index}`}
                            platform={platform}
                            onClick={onPlatformSelected}
                        />
                    ))
                )}
            </div>
            <footer>
                <small>
                    Don't have an account yet?{' '}
                    <a href="/signup">Sign Up Free</a>
                </small>
            </footer>
        </div>
    )
}
