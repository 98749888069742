import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import PlatformIcon from 'components/frontend/signup/PlatformIcon'
import { fieldInput } from 'components/ui/form/renderers'
import { composeValidators, required, requireNoSpaces } from 'utils/validators'
import CsrfField from './CsrfField'
import PlanIdField from './PlanIdField'
import LoginHeader from './LoginHeader'
import { usePlanId } from './hooks/usePlanId'
import { makeAuthTitle } from './utils/index'
import { useLocation } from 'react-router'

/**
 * Validate a Shopify URL for login
 *
 * @param {string} value - The Shopify URL to validate.
 * @returns {string|undefined} - The error message if the URL is invalid, or undefined if the URL is valid.
 *
 * The URL is valid if it matches the format `shop-url.myshopify.com`.
 * Presents a more helpful error if they use an admin URL like `https://admin.shopify.com/store/shop-url`.
 */
const validateShopifyUrl = (value) => {
    if (!value) return undefined

    const cleanUrl = value.replace(/https?:\/\/(www\.)?/, '')

    if (/^[\w-]+\.myshopify\.com$/.test(cleanUrl)) {
        return undefined
    }

    const adminMatch = cleanUrl.match(/\/store\/([\w-]+)(?:\/|$)/)
    if (adminMatch) {
        return `Please use the format: ${adminMatch[1]}.myshopify.com`
    }

    return 'Please enter your store URL in the format: shop-url.myshopify.com'
}

export default function ShopifySignupForm() {
    const { title, formRef } = useShopifyForm()

    return (
        <div>
            <LoginHeader title={title} />
            <div className="login-form">
                <Form onSubmit={() => true} columns={3}>
                    {(form) => (
                        <form
                            ref={formRef}
                            rel="noreferrer"
                            className="shopify-form"
                            action="/frontend/shopify_sessions/login"
                            method="post"
                            onSubmit={(e) => {
                                if (!form.valid) {
                                    e.preventDefault()
                                    return false
                                }
                            }}
                            data-testid="shopify"
                        >
                            <input
                                type="hidden"
                                name="commit"
                                value="Authorize"
                            />
                            <CsrfField />
                            <PlanIdField />
                            <p>
                                To continue you must authorize your Shopify
                                store
                            </p>
                            <PlatformIcon platform="shopify" />
                            <div className="authorize">
                                <Field
                                    name="shop"
                                    title="Shopify URL"
                                    placeholder="Your Shopify URL"
                                    required={true}
                                    render={fieldInput}
                                    validate={composeValidators(
                                        required,
                                        requireNoSpaces,
                                        validateShopifyUrl
                                    )}
                                    fullWidth={true}
                                />

                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            form.handleSubmit()

                                            if (!form.valid) {
                                                return false
                                            }

                                            const shopInput =
                                                formRef.current.querySelector(
                                                    'input[name="shop"]'
                                                )
                                            if (shopInput) {
                                                shopInput.value =
                                                    shopInput.value.replace(
                                                        /^https?:\/\/(www\.)?/,
                                                        ''
                                                    )
                                            }

                                            return formRef.current.submit()
                                        }}
                                    >
                                        Authorize
                                    </button>
                                </div>
                                <small className="mb20">
                                    eg: shop-url.myshopify.com
                                </small>
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        </div>
    )
}

function makeShopifyTitle(pathname, planId) {
    return pathname === '/signup' ? makeAuthTitle(planId) : 'Welcome'
}

function useShopifyForm() {
    const planId = usePlanId()
    const location = useLocation()
    const [title, setTitle] = useState(() =>
        makeShopifyTitle(location.pathname, planId)
    )
    const formRef = useRef(null)

    useEffect(() => {
        setTitle(makeShopifyTitle(location.pathname, planId))
    }, [location, planId])

    return { title, formRef }
}
