//
import React, { useState } from 'react'
import LoaderIcon from 'components/LoaderIcon'
import { Link } from 'react-router-dom'
import { usePlatform } from './hooks/usePlatform'
/**
 * SocialButtons Properties
 */

export default function SocialButtons(props) {
    const [loading, setLoading] = useState(false)
    const [selectedPlatform] = usePlatform()
    const { type } = props

    const onSubmit = async (provider) => {
        setLoading(true)

        const csrfTokenEl = document.querySelector('meta[name="csrf-token"]')
        const csrfToken = csrfTokenEl ? csrfTokenEl.getAttribute('content') : ''

        const form = document.createElement('form')
        form.method = 'post'
        form.action = `/user/auth/${provider}`

        const csrfField = document.createElement('input')
        csrfField.name = 'authenticity_token'
        csrfField.type = 'hidden'
        csrfField.value = csrfToken || ''
        form.appendChild(csrfField)

        const platformField = document.createElement('input')
        platformField.name = 'platform'
        platformField.type = 'hidden'
        platformField.value = selectedPlatform
        form.appendChild(platformField)

        /* $FlowFixMe */
        document?.body?.appendChild(form)

        form.submit()
    }

    return (
        <div>
            <p className="social-title">
                or {type === 'signup' ? 'sign up' : 'sign in'} with
            </p>
            {loading && <LoaderIcon />}
            <nav
                className="social-login"
                style={{
                    justifyContent:
                        type === 'signup' ? 'center' : 'space-between',
                }}
            >
                <button
                    title="Google"
                    disabled={loading}
                    type="button"
                    onClick={() => onSubmit('google_oauth2')}
                >
                    <i
                        role="img"
                        aria-label="Google"
                        className="social-icon google"
                    />
                    Google
                </button>

                {type !== 'signup' && (
                    <>
                        <Link
                            disabled={loading}
                            type="button"
                            style={{ textDecoration: 'none' }}
                            to="/depricated_oauth"
                        >
                            <i
                                role="img"
                                aria-label="Facebook"
                                className="social-icon facebook"
                            />
                            Facebook
                        </Link>

                        <Link
                            disabled={loading}
                            type="button"
                            style={{ textDecoration: 'none' }}
                            to="/depricated_oauth"
                        >
                            <i
                                role="img"
                                aria-label="Twitter"
                                className="social-icon twitter"
                            />
                            Twitter
                        </Link>
                    </>
                )}
            </nav>
        </div>
    )
}
