import React from 'react'
import { UnlockForm } from './UnlockForm'
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn'
import { RandomUpsell } from './RandomUpsell'
import AdFish from 'assets/images/frontend/signup-login/password-fish.png'
import AdFlowers from 'assets/images/frontend/signup-login/password-flowers.png'

export const UnlockPage = () => {
    useRedirectIfLoggedIn()

    const upsellContent = [
        {
            pretitle: 'delivery date & time',
            title: 'Make sure every delivery is </br>efficient, fast & fresh',
            description:
                'Ship perishables with confidence and assure your customers will receive their order in a timely manner.',
            imagePath: AdFlowers,
            link: 'https://shipperhq.com/shipping-perishables',
        },
        {
            pretitle: 'delivery date & time',
            title: 'Make sure every delivery </br>is efficient, fast & fresh.',
            description:
                'Ship perishables with confidence and assure your customers will receive their order in a timely manner.',
            imagePath: AdFish,
            link: 'https://shipperhq.com/shipping-perishables',
        },
    ]

    return (
        <main className="login-page">
            <section>
                <UnlockForm />
            </section>
            <aside>
                <RandomUpsell upsellContent={upsellContent} />
            </aside>
        </main>
    )
}
