import React from 'react'
import styled from 'styled-components'
import Marker from 'assets/images/frontend/global/marker-swish-small.png'
import { CircleIcon } from './CircleIcon'

const Box = styled.div`
    background-image: url(${Marker});
    background-repeat: no-repeat;
    background-position: top 0px right 50%;
    padding: 13px 32px 25px;
`

/**
 * A circular icon component w/ a marker swish background
 */
export const CircleIconWithBG = ({
    color,
    backgroundColor,
    width,
    height,
    fontSize,
    iconClassName,
}) => {
    return (
        <Box>
            <CircleIcon
                backgroundColor={backgroundColor}
                width={width}
                height={height}
                color={color}
                fontSize={fontSize}
                iconClassName={iconClassName}
            />
        </Box>
    )
}

CircleIconWithBG.defaultProps = {
    color: 'white',
    backgroundColor: 'dkblue1',
    width: 56,
    height: 56,
    fontSize: 20,
}
