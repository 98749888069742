//
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'
import { fieldInput } from 'components/ui/form/renderers'
import ReCAPTCHA from 'react-google-recaptcha'
import LoginHeader from './LoginHeader'
import { required } from 'utils/validators'
import useSubmitForm from 'hooks/useSubmitForm'
import { useHistory } from 'react-router-dom'
import { RECAPTCHA_SITE_KEY } from 'components/constants'
import { useFlashMessages } from 'hooks/useFlashMessages'

export const UnlockForm = () => {
    const history = useHistory()
    return (
        <div>
            <LoginHeader title="Resend unlock instructions" />
            <div className="login-form">
                <FormView />
                <footer>
                    <button
                        onClick={() => history.push('/login')}
                        className="bottom-link"
                    >
                        <i className="fa fa-long-arrow-left" /> Back to Login
                    </button>
                </footer>
            </div>
        </div>
    )
}

function FormView() {
    const [isSent, setIsSent] = useState(false)
    const { error, loading, submit } = useSubmitForm('/user/unlock')
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const flashMessages = useFlashMessages()
    const flashError = flashMessages.find(([type]) => type === 'alert') || []

    const onSignIn = async (values) => {
        values['g-recaptcha-response'] = recaptchaToken
        setIsSent(false)
        if (await submit(values)) {
            setIsSent(true)
        }
    }

    const errorMessage = error || flashError[1]

    if (isSent && isEmpty(error)) {
        return (
            <div className="info-msg">
                <small>
                    If your email exists, you will receive an email with
                    instructions on how to unlock your account.
                </small>
            </div>
        )
    }

    return (
        <>
            {errorMessage && (
                <div className="fail-msg">
                    <small>{errorMessage}</small>
                </div>
            )}

            <Form onSubmit={onSignIn}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            title="Email"
                            name="user[email]"
                            required={true}
                            /* $FlowFixMe */
                            render={fieldInput}
                            validate={required}
                            fullWidth={true}
                        />
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={setRecaptchaToken}
                            className="recaptcha"
                        />
                        <button
                            type="submit"
                            className="btn btn-lg btn-block btn-orange sharp sign_in_button"
                            disabled={loading}
                        >
                            Resend unlock instructions
                        </button>
                    </form>
                )}
            </Form>
        </>
    )
}
