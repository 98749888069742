import React from 'react'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import {
    fieldInput,
    fieldSelect,
    fieldCheckboxStd,
} from 'components/ui/form/renderers'
import useSubmitForm from 'hooks/useSubmitForm'
import { required } from 'utils/validators'
import {
    MAGENTO_PLATFORM_ID,
    MAGENTO2_PLATFORM_ID,
} from 'components/frontend/signup/constants'

import {
    submitFormEvent,
    PROFILE_INFO_SUBMIT_FAILURE,
    PROFILE_INFO_SUBMIT_SUCCESS,
} from 'utils/googleTagManager'
import { isFreePlan } from 'components/frontend/signup/constants'
import Alert from 'components/ui/Alert'
import {
    TrialTitle,
    TrialText,
    TrialButton,
} from 'components/dashboard/trialWizard/styles'
import AgreedTerms from './AgreedTerms'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'

const SHOPIFY_PLATFORM_ID = '2'

function isPlatformMagento(platformId) {
    const platformIdStr = platformId?.toString()

    return (
        platformIdStr === MAGENTO2_PLATFORM_ID.toString() ||
        platformIdStr === MAGENTO_PLATFORM_ID.toString()
    )
}

function isPlatformShopify(platformId) {
    const platformIdStr = platformId?.toString()
    return platformIdStr === SHOPIFY_PLATFORM_ID
}

const enumToObj = ([key, val]) => ({
    title: key,
    value: val,
})

function useGenericForm() {
    const { userId } = useParams()
    const { submit, error, loading } = useSubmitForm(
        `/profile_info/${userId}`,
        'patch'
    )
    const { currencyCodes, magentoVersions, selectedPlatformId, planId } =
        useSelector(
            ({
                app: {
                    currency_codes = [],
                    platforms = [],
                    selected_platform,
                    plan_id,
                },
            }) => ({
                currencyCodes: currency_codes.map(enumToObj),
                magentoVersions: platforms.map(enumToObj).filter((platform) => {
                    const value = platform.value

                    return (
                        value === MAGENTO_PLATFORM_ID.toString() ||
                        value === MAGENTO2_PLATFORM_ID.toString()
                    )
                }),
                selectedPlatformId: selected_platform,
                planId: plan_id,
            })
        )

    const submitProfileInfo = async (values) => {
        const data = {
            ratesmgr_profile: {
                first_name: values.first_name,
                last_name: values.last_name,
                company_name: values.company_name,
                phone: values.phone,
                agreed_terms: values.agreed_terms,
                ecommerce_platform_id: values.ecommerce_platform_id,
                existing_customer: values.existing_customer,
                user_attributes: {
                    profile_is_set: true,
                    id: userId,
                    currency: values.currency,
                },
            },
            wants_mailing_list: values.wants_mailing_list,
        }

        const response = await submit(data)
        return response
    }

    return {
        loading,
        error,
        submitProfileInfo,
        currencyCodes,
        magentoVersions,
        selectedPlatformId,
        planId,
    }
}

export default function GenericProfileInfoForm() {
    const {
        submitProfileInfo,
        error,
        loading,
        currencyCodes,
        magentoVersions,
        selectedPlatformId,
        planId,
    } = useGenericForm()

    const submitAndRedirect = async (values) => {
        const result = await submitProfileInfo({ ...values })

        // submit GTM event on success
        if (result) {
            submitFormEvent(PROFILE_INFO_SUBMIT_SUCCESS, 'profileInfoForm')
            // redirect to trial wizard
            window.location.href = '/ratesmgr/onboarding'
        } else {
            submitFormEvent(PROFILE_INFO_SUBMIT_FAILURE, 'profileInfoForm')
        }
    }

    // when navigating from the Sign Up form redux state can still be loading
    if (!currencyCodes.length) {
        return (
            <div className="main-cont profiles">
                <div>Loading...</div>
            </div>
        )
    }

    const isTrial = !isFreePlan(planId)

    return (
        <div className="main-cont trial-wizard">
            <div>
                {error && <Alert message={error} />}
                <Form
                    keepDirtyOnReinitialize
                    initialValues={{
                        currency: currencyCodes[0].value,
                        agreed_terms: false,
                        ecommerce_platform_id: selectedPlatformId,
                    }}
                    onSubmit={submitAndRedirect}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <header>
                                <StepIndicator current={1} total={4} />

                                <TrialTitle>Let&apos;s get started</TrialTitle>
                                {isTrial && (
                                    <TrialText>
                                        Fill in your profile information and you
                                        can start getting rates under our
                                        starter plan free for 15 days. No credit
                                        card required.
                                    </TrialText>
                                )}
                            </header>

                            <Field
                                title="First Name"
                                name="first_name"
                                render={fieldInput}
                                validate={required}
                                required
                                fullWidth
                            />
                            <Field
                                title="Last Name"
                                name="last_name"
                                render={fieldInput}
                                validate={required}
                                required
                                fullWidth
                            />
                            <Field
                                title="Company"
                                name="company_name"
                                render={fieldInput}
                                validate={required}
                                required
                                fullWidth
                            />
                            <Field
                                title="Phone Number"
                                name="phone"
                                render={fieldInput}
                                validate={required}
                                required
                                fullWidth
                            />
                            <Field
                                title="Billing Currency"
                                validate={required}
                                required
                                name="currency"
                                component={fieldSelect}
                                options={currencyCodes}
                                fullWidth
                                disabled={isPlatformShopify(selectedPlatformId)}
                            />
                            {isPlatformMagento(selectedPlatformId) && (
                                <Field
                                    title="Magento Version"
                                    validate={required}
                                    required
                                    name="ecommerce_platform_id"
                                    component={fieldSelect}
                                    options={magentoVersions}
                                    fullWidth
                                />
                            )}
                            <Field
                                renderTitle={AgreedTerms}
                                name="agreed_terms"
                                className="terms"
                                required
                                validate={required}
                                type="checkbox"
                                component={fieldCheckboxStd}
                                fullWidth
                            />
                            <Field
                                title="I am an existing WebShopApps customer"
                                name="existing_customer"
                                type="checkbox"
                                component={fieldCheckboxStd}
                                fullWidth
                            />
                            <Field
                                title="Sign up for emails about new features and other ShipperHQ announcements!"
                                name="wants_mailing_list"
                                type="checkbox"
                                component={fieldCheckboxStd}
                                fullWidth
                            />
                            <TrialButton
                                disabled={loading}
                                type="submit"
                                id="create_trial_account"
                            >
                                {isFreePlan(planId) ? 'Submit' : 'Continue'}
                            </TrialButton>
                        </form>
                    )}
                </Form>
            </div>
        </div>
    )
}
