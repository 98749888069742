import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import {
    STARTER_PLAN_ID,
    FEDEX_HAL_PLAN_ID,
    STANDARD_PLAN_ID,
    ADVANCED_PLAN_ID,
    ESSENTIALS_PLAN_ID,
    UPS_ACCESS_POINT_PLAN_ID,
    FREE_LITE_PLAN_ID,
} from '../constants'

export function usePlanId() {
    const location = useLocation()
    const [planId, setPlanId] = useState(null)

    useEffect(() => {
        const { search: url } = location
        switch (true) {
            case !!url.match(/fedexFree/i):
                setPlanId(FEDEX_HAL_PLAN_ID)
                break
            case !!url.match(/upsapfree/i):
                setPlanId(UPS_ACCESS_POINT_PLAN_ID)
                break
            case !!url.match(/proserve-standard/i):
                setPlanId(STANDARD_PLAN_ID)
                break
            case !!url.match(/proserve-advanced/i):
                setPlanId(ADVANCED_PLAN_ID)
                break
            case !!url.match(/selfserve-essentials/i):
                setPlanId(ESSENTIALS_PLAN_ID)
                break
            case !!url.match(/selfserve-basic/i):
                setPlanId(STARTER_PLAN_ID)
                break
            case !!url.match(/platform=woocommerce&free=1/i):
                setPlanId(FREE_LITE_PLAN_ID)
                break
            default:
                setPlanId(null)
        }
    }, [location])

    return planId
}
