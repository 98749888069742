import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import store from 'reducers/store'
import FrontendRouter from './frontend/Router'
import GlobalError from './GlobalError'
import theme from './theme'
import { GlobalStyles } from './theme/GlobalStyles'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

export default function FrontendApp() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <div className="react-app">
                    <GlobalError>
                        <Provider store={store}>
                            <FrontendRouter />
                        </Provider>
                    </GlobalError>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    )
}
