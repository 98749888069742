import React from 'react'
import styled from 'styled-components'
import { color, position, space } from 'styled-system'

export const TrialBox = styled.div`
    position: relative;
`

export const TrialFlexBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
`

export const TrialTitle = styled.h2`
    ${space};
    ${color};
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.23;
    margin-bottom: 10px;
`
TrialTitle.defaultProps = {
    color: 'grey3',
}

export const TrialText = styled.p`
    ${color};
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 30px;
`
TrialText.defaultProps = {
    color: 'greybluedk2',
}

export const CounterText = styled.p`
    ${color}
    ${position}
   font-size: 14px;
`

export const TrialButton = styled.button`
    ${color};
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.greybluelt};
    border: none;
    transition: 300ms;
    backface-visibility: hidden;
    border-radius: 3px;
    min-width: 120px;
    font-size: 14px;
    padding: 6px 12px;
    line-height: 1.428571429;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background: '#3b90ce';
    }
`
TrialButton.defaultProps = {
    color: 'white',
    backgroundColor: 'greybluelt',
}
