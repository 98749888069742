import React from 'react'
import { useSelector } from 'react-redux'
import Backdrop from 'components/dashboard/components/Backdrop'
import SalesforceProfileInfoForm from './SalesforceProfileInfoForm'
import GenericProfileInfoForm from './GenericProfileInfoForm'

export default function ProfileInfoPage() {
    const { isSalesforceUser } = useProfileInfoPage()

    return (
        <Backdrop>
            {isSalesforceUser ? (
                <SalesforceProfileInfoForm />
            ) : (
                <GenericProfileInfoForm />
            )}
        </Backdrop>
    )
}

function useProfileInfoPage() {
    const isSalesforceUser = useSelector(({ app }) => app.is_salesforce_user)

    return { isSalesforceUser }
}
