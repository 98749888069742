import React from 'react'
import PlatformIcon from 'components/frontend/signup/PlatformIcon'
import CsrfField from './CsrfField'
import * as Constants from './constants'
import LoginHeader from './LoginHeader'

export default function BCForm() {
    return (
        <>
            <LoginHeader title="Welcome" />
            <form
                rel="noreferrer"
                className="button_to"
                method="post"
                action="https://login.bigcommerce.com/login"
            >
                <CsrfField />
                <PlatformIcon platform={Constants.BIGCOMMERCE} />
                <p className="mb20">To login...</p>
                <button type="submit" className="btn btn-lg btn-orange mb30">
                    Sign in to BigCommerce
                </button>
            </form>
        </>
    )
}
